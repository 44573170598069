import React from 'react';
import MetaTitle from '../pages/MetaTitle';
import { Box, Container, Stack, UnorderedList, ListItem, Flex, SimpleGrid, GridItem, Image, VStack, Link, Center } from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import Button from '../component/Button/CustomeButton';
import InnerBanner from '../component/InnerBanner/InnerBanner'

const BoxingAcademy = () => {

    const metaTitle = "Perpetua Boxing Academy | Perpetua Fitness"
    // const metaDesc = "Get ready to dominate the HYROX '24 season. HYROX simulation with Perpetua Fitness in Windmill Lane, Dublin 2."

    return (
        <>
            <MetaTitle title={metaTitle} />
            <InnerBanner
                backgroundImage='url(../images//boxingAcademy.webp)'
                title='Perpetua Boxing Academy '
                subtextpara={<>€99 for the 4-Week Course<br />Perpetua Fitness Dublin, Lennox Street </>}
                issubtextpara={true}
                isButton={false}
                issubtitleRight={false}
                isSubtext={false}
                width={{ sm: "90%", md: '70%', lg: '80%', xl: "100%" }}
                buttontitle2='Sign up today!'
                Link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=41&sTG=103&prodId=108056'
                isButton3={true}
            />

            <Box padding={{ sm: '50px 0', md: '70px 0', xl: "80px 0" }} backgroundColor='#000'>
                <Container maxW='container.xl' >
                    <Heading as='h2' title='Perpetua Boxing Academy' variant="small" padding='0 0 20px' lineHeight='1' color='#fff' textAlign='center' />
                    <Text title='Looking to refine your boxing skills or get started on your fitness journey? Join us for Perpetua Fitness Boxing Academy, a 4-week training program designed for beginners or those wanting to perfect the fundamentals of boxing. Whether you’re looking to gain the skills needed to fully enjoy our Rumble classes or simply want to get your boxing basics right, this academy will help you build a solid foundation in technique, conditioning, and confidence. Great fighters are made on the basics, and this course will give you the knowledge you need to move forward in your boxing journey.' color='#fff' textAlign='center' />
                </Container>
            </Box>

            <Box padding={{ sm: "50px 0", md: "60px 0", xl: "80px 0" }}>
                <Container maxW='container.xl'>

                    <SimpleGrid
                        columns={{ sm: "1", md: "2" }}
                        columnGap={10}
                        rowGap={2}
                        alignItems='center'
                        justifyContent='center'
                    >
                        <GridItem>
                            <Image src='./images/boxing1.webp' margin="0 auto" />
                        </GridItem>
                        <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                            <Heading title='Our Next Boxing Academy Event' variant='large' />
                            <UnorderedList padding={{ sm: "15px 0", md: "20px 0" }} >
                                <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px'> <span style={{ fontWeight: '700', fontSize: '18px' }}>Location: </span> Perpetua Fitness Dublin, Lennox Street </ListItem>
                                <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px'><span style={{ fontWeight: '700', fontSize: '18px' }}>Start Date: </span> Saturday, February 15th, 2025.</ListItem>
                                <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px' ><span style={{ fontWeight: '700', fontSize: '18px' }}>Time: </span> Every Saturday, 12:30pm – 1:30pm (60 minutes).</ListItem>
                                <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px' ><span style={{ fontWeight: '700', fontSize: '18px' }}>Price: </span> €99 one off payment for our 4 week academy.</ListItem>
                                 
                            </UnorderedList>
                        </GridItem>
                    </SimpleGrid>
                    <Flex direction={{ sm: "column-reverse", md: "row" }} gap='15px' padding={{ sm: "25px 5px 0", md: "50px 0 0" }}>
                        <Box padding={{ sm: "20px 0 0", md: "0" }} width={{ md: "50%" }}>
                            <Heading title='Fitness Boxing Academy Course Details' variant='large' lineHeight='1.1' />
                            <UnorderedList padding={{ sm: "15px 0", md: "20px 0" }} >
                                <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px'> <span style={{ fontWeight: '700', fontSize: '18px' }}>When: </span> Saturdays, starting February 15th, 12:30pm – 1:30pm. </ListItem>
                                <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px'><span style={{ fontWeight: '700', fontSize: '18px' }}>Focus: </span> Boxing fundamentals, footwork, punching technique, and conditioning.</ListItem>
                                <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px' ><span style={{ fontWeight: '700', fontSize: '18px' }}>Target Audience: </span> Complete beginners or anyone looking to refine their boxing basics.</ListItem>
                                <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px' ><span style={{ fontWeight: '700', fontSize: '18px' }}>Goal: </span> To provide you with a strong boxing foundation that will make you a more effective and confident boxers, whether for Rumble classes or your own fitness progression.</ListItem>
                            </UnorderedList>
                        </Box>
                        <Box width={{ md: "50%" }}>
                            <Image src='./images/boxing2.webp' margin="0 auto" />
                        </Box>
                    </Flex>
                </Container>
            </Box>

            <Box padding={{ sm: '50px 0', md: '70px 0', xl: "80px 0" }} backgroundColor='#000'>
                <Container maxW='container.xl' >
                    <Heading as='h2' title='What to Expect' variant="small" padding='0 0 20px' lineHeight='1' color='#fff' textAlign='center' />
                    <Text title='Each session will be a proper boxing workout, incorporating the essential skills every boxer needs. We’ll cover everything from basic stance and footwork to developing powerful, accurate punches. Expect a challenging, yet supportive environment where your skills will grow week by week. ' color='#fff' textAlign='center' />

                    <Text title='If the program goes well, we’re excited to continue and possibly offer it monthly for those who want to continue training. ' color='#fff' textAlign='center' />
                    <Center paddingTop='30px'>
                        <Link href='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=41&sTG=103&prodId=108056' isExternal={true}>
                            <Button
                                title='Sign up today!'
                                color='#fff'
                            />
                        </Link>
                    </Center>
                </Container>
            </Box>

            <Box padding={{ sm: '50px 0', md: '70px 0', xl: "80px 0" }} backgroundColor='#fff'>
                <Container maxW='container.xl' >
                    <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='top'>
                        <Box w={{ base: '100%', lg: '50%' }} position={{ base: 'relative', md: 'sticky' }} top={{ base: '0', md: '100px' }} height={{ base: 'auto', md: '200px' }}
                        >
                            <Heading as='h2' title='4-Week Boxing Academy Weekly Breakdown' variant="large" margin='0 0 40px' lineHeight='1' width={{ sm: "100%", md: '80%' }} />
                        </Box>
                        <Box w={{ base: '100%', lg: '50%' }} padding={{ sm: '0', lg: '0 40px' }}>
                            <VStack spacing={2} align='stretch' width={"full"}  >
                                <Stack padding='0 0 30px' borderBottom='1px solid #D9D9D9'>
                                    <Heading as='h5' title='Week 1: Mastering the Fundamentals – Stance, Footwork & Straight Punches' variant="smallmid" padding='0 0 15px' color='Red' />

                                    <Text as='p' title='In the first week, we’ll focus on building a solid foundation by eaching the core fundamentals of boxing. You’ll learn the proper stance for balance and mobility, practice essential footwork and effective movement, and work on mastering straight punches--your primary tool for offense. Whether you’re new to boxing or refining your skills, Week 1 ensures you’re starting off strong.' color='Grey' padding='0 0 8px' />
                                </Stack>
                                <Stack padding='30px 0' borderBottom='1px solid #D9D9D9'>
                                    <Heading as='h5' title='Week 2: Power and Precision – Hooks & Uppercuts' variant="smallmid" padding='0 0 15px' color='Red' />

                                    <Text title='Week 2 is all about adding power and variety to your punches. We’ll introduce hooks and uppercuts—two of boxing’s most explosive strikes. You’ll learn how to throw them with both force and accuracy, while also improving your ability to deliver these punches from different agnles and within combinations.' padding='0 0 8px' color='Grey' />

                                </Stack>

                                <Stack padding='30px 0' borderBottom='1px solid #D9D9D9'>
                                    <Heading as='h5' title='Week 3: Defensive Mastery – Guard, Slips and Rolls' variant="smallmid" padding='0 0 15px' color='Red' />
                                    <Text title='Defense is just as important as offense in boxing. In Week 3, you’ll learn how to protect yourself with solid defensive techniques. From maintaining a tight guard to slipping punches and rolling with attacks, you’ll practice the skills that keep you safe and set up counter-attacks. The focus will be on anticipation, timing, and quick reactions.' color='Grey' padding='0 0 8px' />
                                </Stack>

                                <Stack padding='30px 0 0'>
                                    <Heading as='h5' title='Week 4: Putting it all together - combination drills' variant="smallmid" padding='0 0 15px' color='Red' />
                                    <Text title='In the final week, we’ll bring everything together with combination drills that integrate the techniques you’ve learned. You’ll practice fluid combinations of punches, defensive moves, and footwork to make your movements seamless and unpredictable. By the end of the week, you will have the skills to execute what you’ve learned in a more dynamic Rumble class setting.' color='Grey' />
                                </Stack>
                            </VStack>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Box padding={{ sm: '50px 0', md: '60px 0', xl: "60px 0" }} backgroundColor='#f7f7f7'>
                <Container maxW='container.xl' >
                    <Heading as='h2' title='What You Need' variant="small" padding='0 0 20px' lineHeight='1' color='#000' textAlign='center' />
                    <Text title='Wraps are essential to take part in the academy. Perpetua Boxing Gloves are provided for you, but you will need to either bring your own wraps or purchase a branded pair of wraps at reception upon arrival.' color='#000' textAlign='center' />

                    <Center paddingTop='30px'>
                        <Link href='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=41&sTG=103&prodId=108056' isExternal={true}>
                            <Button
                                title='Sign up today!'
                                color='#000'
                            />
                        </Link>
                    </Center>
                </Container>
            </Box>

            {/* <Box padding="50px 0" backgroundColor='#f7f7f7'>
                <Container maxW='container.xl'>
                    <Box>
                        <Heading title='Limited spots available, sign up today! ' variant='small' textAlign='center' />
                        <Center paddingTop='30px'>
                            <Link href='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=108056' isExternal={true}>
                                <Button
                                    title='Sign up'
                                    color='#000'
                                />
                            </Link>
                        </Center>
                    </Box>
                </Container>
            </Box> */}
        </>
    )
}

export default BoxingAcademy;
