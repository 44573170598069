import React from 'react';
import { Box, Container, GridItem, SimpleGrid, Image, Center, UnorderedList, ListItem } from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';

const Story = () => {
    return (
        <>
            <Box
                backgroundImage={{ sm: 'url(./images/story_image_banner.webp)', md: 'url(./images/story_image_banner.webp)' }}
                backgroundColor='black'
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                height={{ sm: '40vh', md: '500px' }}
                backgroundSize="cover"
            >
                <Container maxW='container.2xl' backgroundColor='rgba(0,0,0,0.5)' p={{ sm: '0 20px', md: "0 70px", lg: "0 130px" }}  justifyContent="center" display='flex' height='100%' alignItems="end" >
                    <Box
                        paddingBottom="30px"
                        textTransform="uppercase"
                        alignItems="center"
                        display='flex'
                        flexDirection='column'
                        className='wow animate fadeInUp'
                    >
                        <Text as='strong' fontSize={{ base: 'xs', md: "sm" }} color='#fff' title='About Us ' fontFamily='BlenderProBold'></Text>
                        <Heading title='About Perpetua Fitness' color='#fff' lineHeight="1" padding="0px 0px 5px" variant="largheading" textAlign='center' />
                    </Box>
                </Container>
            </Box>

            <Box padding={{ sm: "50px 0 30px", md: "70px 0 25px", lg: "100px 0 50px" }}>
                <Container maxW='container.xl'>
                    <Center flexDirection='column'>
                        <Heading title='Our Mission' variant='extrasmall' />
                        <Heading title='Ireland’s Ultimate Fitness Experience' variant='large' lineHeight='0.9' padding='15px 0' textAlign='center' width={{md:"70%"}} />
                        <Text title='At Perpetua, community and movement are at the core of everything we do. We are passionate about intelligent programming, functional fitness, and creating an environment where training is both effective and enjoyable. Here, fitness is more than just a workout—it’s a shared experience.' textAlign='center' width={{md:"70%"}} />
                    </Center>
                </Container>
            </Box>
            <Box padding={{ sm: "35px 0", md: "50px 0 25px", lg: "50px 0 50px" }}>
                <Container maxW='container.xl'>
                    <SimpleGrid
                        columns={{ sm: '1', md: '2' }}
                        columnGap={10}
                        rowGap={2}
                        alignItems='center'
                    >
                        <GridItem>
                            <Image src='./images/story_image_8.webp' alt='Best Fitness Experience' />
                        </GridItem>
                        <GridItem paddingTop={{sm:"30px", md:"0"}}>
                            <Heading title='Our Commitments' variant='extrasmall' color='grey' />
                            <Box paddingTop='25px'>
                                <Heading title='A Promise of Excellence' variant='small' />
                                <Text title='We’ve created an environment where integrity, growth, and support are at the core of everything we do. Here, doing the right thing is recognized and rewarded. We strive to uplift one another, helping each individual become stronger, more knowledgeable, and more resilient through professional training systems and dedicated support.' padding='5px 0 0' />
                            </Box>
                            <Box paddingTop='25px'>
                                <Heading title='An Inclusive Community' variant='small' />
                                <Text title="For over 16 years, we have built more than just a gym—we've cultivated a thriving community. When you join Perpetua, you’re not just signing up for fitness; you’re becoming part of a supportive network where connections are made, friendships grow, and encouragement fuels your journey to better health and happiness." padding='5px 0 0' />
                            </Box>
                            <Box paddingTop='25px'>
                                <Heading title='Passion-Driven Training' variant='small' />
                                <Text title='Our passion is creating a safe, effective, and empowering training space where like-minded individuals come together to achieve their goals. Our full-time professional coaches are deeply committed to guiding and inspiring you through every step of your fitness journey.' padding='5px 0 0' />
                            </Box>
                        </GridItem>
                    </SimpleGrid>
                </Container>
            </Box>
            <Box padding={{ sm: "35px 0", md: "50px 0 25px", lg: "50px 0 25px" }}>
                <Container maxW='container.xl'>
                    <SimpleGrid
                        columns={{ sm: '1', md: '2' }}
                        columnGap={10}
                        rowGap={2}
                        alignItems='center'
                        display={{sm:"flex", md:"grid"}}
                        flexDirection= {{sm:"column-reverse", md:"row"}}
                    >
                        <GridItem paddingTop={{sm:"30px", md:"0"}}>
                            <Heading title='Our Values' variant='extrasmall' color='grey' />
                            <Box paddingTop='25px'>
                                <Heading title='We Are a Team' variant='small' />
                                <Text title='At Perpetua, we believe in the power of teamwork. Whether you’re a coach, an athlete, or just beginning your fitness journey, we support and push each other to be better every day. Together, we celebrate progress, overcome challenges, and strive for excellence. No one is left behind—we rise as one.' padding='5px 0 0' />
                            </Box>
                            <Box paddingTop='25px'>
                                <Heading title='Our Community is Everything' variant='small' />
                                <Text title="More than a gym, Perpetua is a home for those who seek connection, motivation, and shared success. We take pride in the inclusive and welcoming environment we’ve built over the years, where everyone is valued and encouraged. Here, friendships are formed, support is abundant, and every achievement—big or small—is celebrated." padding='5px 0 0' />
                            </Box>
                            <Box paddingTop='25px'>
                                <Heading title='Be Sound' variant='small' />
                                <Text title='Strength isn’t just physical—it’s mental, emotional, and ethical. At Perpetua, we emphasize balance in all aspects of life. We train with intention, take care of our bodies, and cultivate a mindset of resilience and well-being. Being sound means making choices that support long-term health, happiness, and personal growth.' padding='5px 0 0' />
                            </Box>
                            <Box paddingTop='25px'>
                                <Heading title='We Respect Everything' variant='small' />
                                <Text title='Respect is the foundation of our culture. We respect the space we train in, the people we train with, and the journey we’re all on. Whether it’s through kindness, accountability, or mindful training, we uphold a standard of excellence that extends beyond the gym and into our daily lives.' padding='5px 0 0' />
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Image src='/images/story_our_values.webp' alt='Best Fitness Experience' />
                        </GridItem>
                    </SimpleGrid>
                </Container>
            </Box>

            <Box padding={{ sm: "35px 0", md: "50px 0", lg: "75px 0" }}>
                <Container maxW='container.xl'>
                    <SimpleGrid
                        columns={{ sm: '1', md: '2' }}
                        columnGap={10}
                        rowGap={2}
                    >
                        <GridItem>
                            <Image src='./images/story_image_5.webp' alt='Diversity' />
                        </GridItem>
                        <GridItem>
                            <Image src='./images/story_image_7.webp' alt='Diversity' />
                        </GridItem>
                    </SimpleGrid>
                    <Center flexDirection='column' paddingTop={{ sm: "30px", md: "50px" }}>
                        <Heading title='Diversity, Equity & Inclusion' variant='medium' />
                        <Text title='At Perpetua Fitness, we are committed to fostering a community where everyone feels valued, supported, and empowered. We strive to create an inclusive space where diversity is celebrated, equality is upheld, and every individual has a true sense of belonging.' padding='10px 0 0' width={{ md: '50%' }} textAlign='center' />
                    </Center>
                </Container>
            </Box>

            <Box
                backgroundColor='#000'
                padding={{ sm: "60px 15px", md: "80px 30px", xl: "0" }}
                height='100%'
            >
                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    alignItems='center'
                    height='100%'
                >
                    <GridItem>
                        <Image src='./images/story_image_4.webp' alt='Perpetua Fitness Today' />
                    </GridItem>
                    <GridItem paddingTop={{ sm: "30px", md: "0", xl: "50px" }} paddingBottom={{ sm: "0", xl: "50px" }} paddingRight={{ sm: "0", md: "50px" }}>
                        <Box className='wow animate fadeInUp'>
                            <Heading title='Perpetua In History' color='#fff' lineHeight="1" padding="0px 0px 5px" variant="medium" />
                            <Text title='Perpetua Fitness was founded with a vision to create a fitness community that went beyond the traditional gym experience. The founders, driven by a passion for fitness and well-being, wanted to establish a space where people could not only train their bodies but also nourish their minds and spirits. The journey began in a small studio, where the focus was on delivering high-quality, personalized training sessions.' color='#c79d5b' padding='15px 0 0' fontSize={{ sm: "12px", md: "14px" }} />
                            <Text title="From the outset, Perpetua Fitness emphasized community. The founders believed that a supportive and inclusive environment was key to achieving fitness goals. They organized events, challenges, and group activities that fostered a sense of belonging among members. This approach quickly gained traction, and the studio's membership grew as people were drawn to the positive and motivating atmosphere." color='#fff' padding='15px 0 0' fontSize={{ sm: "12px", md: "14px" }} />
                            <Text title="As the community expanded, so did the offerings. Perpetua Fitness introduced a variety of classes, ranging from high-intensity interval training to boxing. The goal was to cater to diverse fitness preferences and ensure that everyone could find something they enjoyed. This diversification helped attract a broader audience and solidified Perpetua Fitness's reputation as a comprehensive fitness center." color='#fff' padding='15px 0 0' fontSize={{ sm: "12px", md: "14px" }} />
                            <Text title="Perpetua Fitness was always ahead of the curve in embracing new fitness trends and technologies. They incorporated the latest equipment and training methodologies to provide the best possible experience for their members. This commitment to innovation included the use of fitness tracking apps, virtual classes, and interactive workouts, which became particularly valuable during times when physical attendance was limited." color='#fff' padding='15px 0 0' fontSize={{ sm: "12px", md: "14px" }} />
                            <Text title="Like any business, Perpetua Fitness faced its share of challenges. Economic downturns, competition, and the COVID-19 pandemic tested the resilience of the community. However, the strong foundation of member support and the adaptability of the management team allowed Perpetua Fitness to navigate these obstacles. They shifted to online classes during lockdowns, offered flexible membership options, and ensured that their community stayed connected even when physically apart." color='#fff' padding='15px 0 0' fontSize={{ sm: "12px", md: "14px" }} />
                        </Box>
                    </GridItem>
                </SimpleGrid>
            </Box>

            <Box
                backgroundImage='url(./images/story_buttom.webp)'
                backgroundColor='black'
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                height={{ sm: '100vh', md: '550px', lg: '600px', xxl: '767px' }}
                backgroundSize={"cover"}
            >
                <Container maxW='container.2xl' p={{ sm: '0 20px', md: "0 70px", lg: "0 130px" }} backgroundColor='rgba(0,0,0,0.6)' width='100%' height='100%' ustifyContent="center" alignItems='center' display='flex'>
                    <Box className='wow animate fadeInUp'>
                        <Heading title='Perpetua Fitness Today' color='#fff' lineHeight="1" padding="0px 0px 5px" variant="medium" />
                        <Text title="Today, Perpetua Fitness stands as a testament to the power of community-driven fitness. With multiple locations and a diverse range of programs, it continues to inspire and support individuals in their fitness journeys. The founders' commitment to creating a welcoming and dynamic environment remains at the core of everything they do." color='#c79d5b' padding='15px 0 0' width={{ sm: "90%", md: "80%", lg: '65%', xl: "50%" }} fontSize={{ sm: "12px", md: "14px" }} />
                        <Text title='Looking to the future, Perpetua Fitness aims to expand its reach even further. Plans are in place to open new branches, introduce more specialized training programs, and leverage advanced fitness technologies. The mission remains the same: to help people achieve their best selves through fitness, community, and innovation,' color='#fff' padding='15px 0 0' width={{ sm: "90%", md: "80%", lg: '65%', xl: "50%" }} fontSize={{ sm: "12px", md: "14px" }} />

                        <UnorderedList listStyleType="none" padding='15px 0 0'>
                            <ListItem color='#fff' fontSize={{ sm: "12px", md: "14px" }} fontFamily='Poppins' marginLeft='-17px' paddingTop='7px'>
                                <span style={{ fontWeight: '700' }}>Community: </span>  Building a supportive and inclusive environment for all members.
                            </ListItem>
                            <ListItem color='#fff' marginLeft='-17px' fontFamily='Poppins' fontSize={{ sm: "12px", md: "14px" }} paddingTop='7px'>
                                <span style={{ fontWeight: '700' }}>Innovation: </span> Continuously evolving and incorporating new fitness trends and technologies.
                            </ListItem>
                            <ListItem color='#fff' fontWeight='400' marginLeft='-17px' fontFamily='Poppins' fontSize={{ sm: "12px", md: "14px" }} paddingTop='7px'>
                                <span style={{ fontWeight: '700' }}>Personalization: </span>  Offering tailored programs to meet individual needs and goals.
                            </ListItem>
                            <ListItem color='#fff' marginLeft='-17px' fontFamily='Poppins' fontSize={{ sm: "12px", md: "14px" }} paddingTop='7px'>
                                <span style={{ fontWeight: '700' }}> Well-Being: </span> Focusing on holistic health, including mental and emotional wellness.
                            </ListItem>
                            <ListItem color='#fff' marginLeft='-17px' fontFamily='Poppins' fontSize={{ sm: "12px", md: "14px" }} paddingTop='7px'>
                                <span style={{ fontWeight: '700' }}>Resilience: </span>  Overcoming challenges and adapting to changing circumstances.
                            </ListItem>
                        </UnorderedList>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default Story;