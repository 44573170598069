import React from 'react';
import MetaTitle from './MetaTitle';
import Text from '../component/SubHeading/SubHeading';
import Heading from '../component/Heading/Heading';
import { Box, Image, Container, Flex, Stack, Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton, Link, SimpleGrid, GridItem, VStack } from '@chakra-ui/react';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import ContactForm from '../component/Form/ContactForm';
import LinkButton from '../component/Button/LinkButton';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";

const Beginners = () => {

    const metaTitle = "Beginners | Crossfit Dublin | Perpetua Fitness";
    const metaDesc = '';

    return (
        <>
            <MetaTitle title={metaTitle} content={metaDesc} />
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <VideoBanner 
                videobanner='../video/CF-banner.mp4'
                title={<Box>CROSSFIT DUBLIN <br /> HOW TO GET STARTED </Box>}
                subtitle='4 Week CrossFit Academy Course'       
                isButton={true} 
                issubtextpara={false} 
                buttontitle="Book a consult"
                margin="0"
                Link="/join-today"
                videoModal='https://www.youtube.com/embed/T-4WLpZYVBw' 
                targetBlank={false}
                modalButtontitle="Watch Our Video"
                issubtitleRight={false} 
            />
            <Box
                padding={{ sm: "50px 0", md: "70px 0 50px" }}
                textAlign='center'
            >
                <Container maxW="container.xl">
                    <Heading title={<Box>Welcome to CrossFit Dublin. <br /> Established 2008</Box>} variant="extralarge" margin="0px 0px 25px"></Heading>
                    <Text title="For new beginners to CrossFit, our 4 week CrossFit Academy course is the best intro for new beginners to learn everything for classes." as='p' margin="0px auto 15px" fontSize="18px" width="90%" ></Text>
                    <Text title="We schedule 3 sessions a week where we start with the basics before progressing each week so you develop the technique needed for classes. A new group starts every 5 to 6 weeks." as='p' margin="0px auto 15px" fontSize="18px" width="90%"></Text>
                </Container>
            </Box>

            <Container maxW="container.xl">
                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='../images/ThredAndshredFirsTimers1.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                      
                        <Heading title='Arrange a call/ meeting with our Head Coach' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title=" We would love to meet you at our facility or jump on a call to discuss our induction options. You will then understand the amazing process we have here so you learn everything correctly from the start." padding='5px 0' />
                    </GridItem>
                </SimpleGrid>
                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='../images/ThredAndshredFirsTimers2.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Heading title='Join The Academy' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="Prior to joining the regular classes, a new member would join our four week group academy. We have morning or evening groups for you to choose from and these take place every 5 weeks. During these four weeks, you will learn all the movements and correct form/technique needed for classes." />
                    </GridItem>
                </SimpleGrid>

                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='../images/ThredAndshredFirsTimers4.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Heading title=' Learn More About our Program & Community' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="During the 4 week program, we will educate our new members on how the class program works and how to approach the first few weeks which a very exciting time." />
                    </GridItem>
                </SimpleGrid>
                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='../images/ThredAndshredFirsTimers7.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Heading title='Meet Our Team' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="Our experienced and friendly coaching team will be excited to welcome you on your CrossFit journey. Each week, we will rotate coaches so you get to meet our full team before joining our CrossFit classes. " />
                    </GridItem>
                </SimpleGrid>

                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0 50px", md: "50px 0 100px" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='../images/ThredAndshredFirsTimers6.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Heading title='Graduate & Begin A Membership' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="Once you have finished the induction process, our team will set up a membership for you so you can get started straight away" />
                    </GridItem>
                </SimpleGrid>
            </Container>

            <Flex p={{ sm: '0px 0px 70px', md: '20px 0px 90px' }}>
                <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
                    <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
                        <Box width={{ sm: '100%', md: '50%' }}>
                            <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0px 0px 30px' margin='0px auto' ></Heading>
                                <Text title='Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us.' width='80%' ></Text>
                            </Box>
                        </Box>
                        <Box width={{ sm: '100%', md: '50%' }}>
                            <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                                <AccordionItem >
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title=' What is the CrossFit Academy?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='For New beginners, our 4 week CrossFit Academy course is the best intro for new beginners to learn everything for classes. ' margin='0px 0px 10px'></Text>
                                        <Text title='We schedule 3 sessions a week where we start with the basics before progressing each week so you develop the technique needed for classes. A new group starts every 5 to 6 weeks.' margin='0px 0px 20px' />
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="I'm an experienced CrossFitter, do I have to join the CrossFit Academy?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title={<Box>If you have done CrossFit for more than 6 months, you can join classes and start a membership. Please email <Link href="mailto:crossfit@perpetua.ie" textDecoration='underline'>crossfit@perpetua.ie</Link> to organise a class drop in and we can get set up a membership for you.</Box>} margin='0px 0px 20px'></Text>

                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='Do you offer a free trial class?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title="If you have over 6 months CrossFit experience, yes we can organise a trial class for you to experience the coaching and facility. If you haven't done CrossFit before, unfortunately we do not offer a trial class. You can organise a tour with our Head Coach to see the facility and find out more details about our start up process." margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="What do I get for my monthly membership fee?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='You get unlimited access to CrossFit classes and also full access to the JustGYM area.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="How many classes are on each day?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title=' We have 12-14 CrossFit classes a day Monday to Friday and weekends range from 3-4 classes a day. With your CrossFit membership, you get unlimited access to all CrossFit classes.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="How do I become a CrossFit member?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title={<Box>If you haven't done CrossFit before, simply email <Link href="mailto:crossfit@perpetua.ie" textDecoration='underline'>crossfit@perpetua.ie</Link> to sign up for the CrossFit Academy.</Box>} margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="What are your hours of operation?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title=' WML: Monday-Thursday 6am-9pm | Friday 6am-8pm | Saturday-Sunday 8am-2pm' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title=" Do you have a place where I can securely leave my stuff while I work out?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title="We have lockers in our men’s and women’s changing rooms where you can leave your belongings. Each locker allows you to create a single-use code while you’re in the facility." margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="Do you have showers?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title="Yes, we have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)" margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>

                            </Accordion>
                        </Box>
                    </Stack>
                </Container>
            </Flex>

            <VStack backgroundColor='black' padding='96px 0px' borderBottom='1px solid #232323'>
                <Container
                display='flex'
                maxW='container.xl'
                flexDirection={{ sm: 'column', md: 'row' }}
                alignItems='top'
                alignContent='center'
                justifyContent='center'
                justifyItems='center'
                >
                    <Box w={{sm:'100%', md:'50%',lg:'40%'}} textAlign={{ base: 'center', lg: 'left' }}           marginBottom={{ base: '50px', lg: '0' }}>
                        <Heading title='LOOKING FOR MORE INFORMATION' variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='white'></Heading>
                        <Heading title='Chat with our head coach ' variant='smallmid' lineHeight='1' margin='0px 0px 30px' color='white'></Heading>
                        <NavLink to="/contact-us" _hover={{ textDecoration: 'none' }}>
                            <LinkButton
                                title='BOOK A CALL'
                                Imguri='/images/red_arrow.svg'
                                border='2px solid' borderColor='Red'
                                padding='20px 25px'
                                color='White'
                                className="wow fadeInUp"
                            ></LinkButton>
                        </NavLink>
                    </Box>
                    <Box w={{sm:'100%', md:'50%',lg:'60%'}}>
                        <ContactForm pageName="CrossFit"></ContactForm>
                    </Box>
                </Container>
            </VStack>
        </>
    )
}

export default Beginners;